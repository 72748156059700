import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
//@ts-ignore
import * as FileSaver from 'file-saver';
import { Workbook } from 'exceljs';
import * as XLSX from 'xlsx';

import { base64Logo } from '../assets/logo';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

    constructor() { }

    public exportAsExcelFile(sheetNames: any[], dataArr: any[], excelFileName: string): void {

        const sheetName = sheetNames;

        let wb = XLSX.utils.book_new();
        for (var i = 0; i < sheetName.length; i++) {
            let ws = XLSX.utils.json_to_sheet(dataArr[i]);
            XLSX.utils.book_append_sheet(wb, ws, sheetName[i]);
        }

        const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);

    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }


    public sidePotExport(classNames: any[], dataArr: any[], fileName: string): void {

        let workbook = new Workbook();

        // const logo = workbook.addImage({
        //     base64: base64Logo,
        //     extension: 'png',
        // });

        for (let y = 0; y < dataArr.length; y++) {

            let worksheet = workbook.addWorksheet(classNames[y]);

            // //Logo row
            // worksheet.addImage(logo, {
            //     tl: { col: 0, row: 0 },
            //     ext: { width: 200, height: 63 }
            // });

            // worksheet.addRow([]);
            // worksheet.addRow([]);
            worksheet.addRow([]);
            worksheet.addRow([]);
            //Add new row
            let titleRow = worksheet.addRow([classNames[y]]);

            // Set font, size and style in title row.
            titleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'single', bold: true };

            //Blank Row
            worksheet.addRow([]);

            // for (let i = 0; i < Object.entries(dataArr[y]).length; i++) {
            for (const [key, value] of Object.entries(dataArr[y])) {

                const header = [key, "Horse", "Time", "Place", "Points"];

                //@ts-ignore
                const data = value;

                let headerRow = worksheet.addRow(header);
                // Cell Style : Fill and Border
                headerRow.eachCell((cell, number) => {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'ffffff' },
                        bgColor: { argb: 'ffffff' }
                    }
                    cell.font = { name: 'Calibri', family: 4, size: 12, bold: true };
                    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                });

                //@ts-ignore
                data.forEach(d => {

                    const propertyValues = Object.values(d);
                    propertyValues.shift();
                    let row = worksheet.addRow(propertyValues);

                    row.eachCell((cell, number) => {
                        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                    });

                    // let qty = row.getCell(5);
                    // let color = 'FF99FF99';
                    // if (+qty.value < 500) {
                    //     color = 'FF9999'
                    // }
                    // qty.fill = {
                    //     type: 'pattern',
                    //     pattern: 'solid',
                    //     fgColor: { argb: color }
                    // }
                }
                );

            }

            worksheet.columns.forEach(function (column) {
                var dataMax = 0;
                //@ts-ignore
                column.eachCell({ includeEmpty: true }, function (cell) {
                    dataMax = cell.value ? cell.value.toString().length : 0;
                })
                column.width = dataMax < 20 ? 20 : dataMax;
            });

        }

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, fileName + '.xlsx');
        });

    }

    public paysheet(sheetName: any, dataArr: any[], fileName: string): void {

        let workbook = new Workbook();

        let worksheet = workbook.addWorksheet(sheetName);

        worksheet.addRow([]);

        //Add new row
        let titleRow = worksheet.addRow(sheetName);
        // Set font, size and style in title row.
        titleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'single', bold: true };

        //Blank Row
        worksheet.addRow([]);

        const header = ["Name", "Phone", "Amount Due", "Notes"];

        //@ts-ignore
        let headerRow = worksheet.addRow(header);
        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ffffff' },
                bgColor: { argb: 'ffffff' }
            }
            cell.font = { name: 'Calibri', family: 4, size: 12, bold: true };
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        });


        for (let z = 0; z < dataArr.length; z++) {
            const data = dataArr[z];

            const propertyValues = Object.values(data);
            let row = worksheet.addRow(propertyValues);

            row.eachCell((cell, number) => {
                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
            });


        }

        worksheet.columns.forEach(function (column) {
            var dataMax = 0;
            //@ts-ignore
            column.eachCell({ includeEmpty: true }, function (cell) {
                dataMax = cell.value ? cell.value.toString().length : 0;
            })
            column.width = dataMax < 20 ? 20 : dataMax;
        });

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, fileName + '.xlsx');
        });

    }


    public runSheet(classNames: any[], dataArr: any[], sidePotArr: any[], fileName: string): void {

        let workbook = new Workbook();

        // const logo = workbook.addImage({
        //     base64: base64Logo,
        //     extension: 'png',
        // });

        for (let y = 0; y < dataArr.length; y++) {

            let worksheet = workbook.addWorksheet(classNames[y]);

            //Logo row
            // worksheet.addImage(logo, {
            //     tl: { col: 0, row: 0 },
            //     ext: { width: 200, height: 63 }
            // });

            // worksheet.addRow([]);
            // worksheet.addRow([]);
            worksheet.addRow([]);
            worksheet.addRow([]);

            //Add new row
            let titleRow = worksheet.addRow([classNames[y]]);
            // Set font, size and style in title row.
            titleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'single', bold: true };

            //Blank Row
            worksheet.addRow([]);

            // const header = ["Name", "Email", "Phone", "Address", "Amount Due", "Paid", "Notes"];

            const header = ["Draw #", "Name", "Horse", "Time", "Rollover", "Sidepots"];

            //@ts-ignore

            let headerRow = worksheet.addRow(header);
            // Cell Style : Fill and Border
            headerRow.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ffffff' },
                    bgColor: { argb: 'ffffff' }
                }
                cell.font = { name: 'Calibri', family: 4, size: 12, bold: true };
                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
            });


            for (let z = 0; z < dataArr[y].length; z++) {
                const data = dataArr[y][z];


                delete data['hasDrawSelection'];
                // delete data['drawSelectionOrder'];

                const propertyValues = [z + 1, ...Object.values(data)];


                let row = worksheet.addRow(propertyValues);

                row.eachCell((cell, number) => {
                    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                });


            }

            worksheet.columns.forEach(function (column) {
                var dataMax = 0;
                //@ts-ignore
                column.eachCell({ includeEmpty: true }, function (cell) {
                    dataMax = cell.value ? cell.value.toString().length : 0;
                })
                column.width = dataMax < 20 ? 20 : dataMax;
            });

            const colD = worksheet.getColumn('E');
            colD.width = 50;

            const colE = worksheet.getColumn('F');
            colE.width = 100;

            const colG = worksheet.getColumn('G');
            colG.hidden = true;



            const flattenedSidepotsForClass: any = Object.values(sidePotArr[y]);

            for (let s = 0; s < flattenedSidepotsForClass.length; s++) {

                const sidepot = flattenedSidepotsForClass[s];

                console.log("Sidepot name", sidepot['sidepotName']);

                let sidepotWorksheet = workbook.addWorksheet(sidepot['sidepotName']);

                //Blank Row
                sidepotWorksheet.addRow([]);
                sidepotWorksheet.addRow([]);

                //Add new row
                let sidepotTitleRow = sidepotWorksheet.addRow([sidepot['sidepotName']]);
                // Set font, size and style in title row.
                sidepotTitleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'single', bold: true };

                //Blank Row
                sidepotWorksheet.addRow([]);


                const sidepotHeader = ["Name", "Horse", "Time"];

                //@ts-ignore

                let sidepotHeaderRow = sidepotWorksheet.addRow(sidepotHeader);
                // Cell Style : Fill and Border
                sidepotHeaderRow.eachCell((cell, number) => {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'ffffff' },
                        bgColor: { argb: 'ffffff' }
                    }
                    cell.font = { name: 'Calibri', family: 4, size: 12, bold: true };
                    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                });



                for (let z = 0; z < sidepot['entries'].length; z++) {
                    const data = sidepot['entries'][z];

                    const propertyValues = Object.values(data);


                    let row = sidepotWorksheet.addRow(propertyValues);

                    row.eachCell((cell, number) => {
                        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                    });


                }


                sidepotWorksheet.columns.forEach(function (column) {
                    var dataMax = 0;
                    //@ts-ignore
                    column.eachCell({ includeEmpty: true }, function (cell) {
                        dataMax = cell.value ? cell.value.toString().length : 0;
                    })
                    column.width = dataMax < 20 ? 20 : dataMax;
                });

            }


            //@ts-ignore
            // worksheet.getCell('A3').value = { formula: 'A1', result: '0', formulaType: 'none'};


        }

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, fileName + '.xlsx');
        });

    }

    public unpaidSidePotExport(classNames: any[], dataArr: any[], fileName: string): void {

        let workbook = new Workbook();

        // const logo = workbook.addImage({
        //     base64: base64Logo,
        //     extension: 'png',
        // });

        for (let y = 0; y < dataArr.length; y++) {

            let worksheet = workbook.addWorksheet(classNames[y]);

            //Logo row
            // worksheet.addImage(logo, {
            //     tl: { col: 0, row: 0 },
            //     ext: { width: 200, height: 63 }
            // });

            // worksheet.addRow([]);
            // worksheet.addRow([]);
            worksheet.addRow([]);
            worksheet.addRow([]);

            //Add new row
            let titleRow = worksheet.addRow([classNames[y]]);
            // Set font, size and style in title row.
            titleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'single', bold: true };

            //Blank Row
            worksheet.addRow([]);



            // const header = ["Name", "Email", "Phone", "Address", "Amount Due", "Paid", "Notes"];

            const header = ["Name", "Phone", "Amount Due", "Notes"];

            //@ts-ignore

            let headerRow = worksheet.addRow(header);
            // Cell Style : Fill and Border
            headerRow.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ffffff' },
                    bgColor: { argb: 'ffffff' }
                }
                cell.font = { name: 'Calibri', family: 4, size: 12, bold: true };
                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
            });


            dataArr[y].sort(function (a: any, b: any) {
                if (a.Name < b.Name) { return -1; }
                if (a.Name > b.Name) { return 1; }
                return 0;
            })

            for (let z = 0; z < dataArr[y].length; z++) {
                const data = dataArr[y][z];



                const propertyValues = Object.values(data);
                let row = worksheet.addRow(propertyValues);

                row.eachCell((cell, number) => {
                    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                });


            }

            worksheet.columns.forEach(function (column) {
                var dataMax = 0;
                //@ts-ignore
                column.eachCell({ includeEmpty: true }, function (cell) {
                    dataMax = cell.value ? cell.value.toString().length : 0;
                })
                column.width = dataMax < 20 ? 20 : dataMax;
            });

        }

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, fileName + '.xlsx');
        });

    }

    getTotalQuantity(items: any) {
        let total = 0;

        for (let y = 0; y < items.length; y++) {

            total = total + parseInt(items[y]['Quantity']);

        }

        return total;
    }


    public itemExport(classNames: any[], dataArr: any[], fileName: string): void {

        let workbook = new Workbook();

        const logo = workbook.addImage({
            base64: base64Logo,
            extension: 'png',
        });


        for (let y = 0; y < dataArr.length; y++) {

            let worksheet = workbook.addWorksheet(classNames[y]);

            //Logo row
            // worksheet.addImage(logo, {
            //     tl: { col: 0, row: 0 },
            //     ext: { width: 200, height: 63 }
            // });

            // worksheet.addRow([]);
            // worksheet.addRow([]);
            worksheet.addRow([]);
            worksheet.addRow([]);

            //Add new row
            let titleRow = worksheet.addRow([classNames[y]]);
            // Set font, size and style in title row.
            titleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'single', bold: true };

            //Blank Row
            worksheet.addRow([]);

            const header = ["Name", "Quantity", "Notes", this.getTotalQuantity(dataArr[y]) + " Total"];

            //@ts-ignore

            let headerRow = worksheet.addRow(header);
            // Cell Style : Fill and Border
            headerRow.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ffffff' },
                    bgColor: { argb: 'ffffff' }
                }
                cell.font = { name: 'Calibri', family: 4, size: 12, bold: true };
                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
            });


            dataArr[y].sort(function (a: any, b: any) {
                if (a.Name < b.Name) { return -1; }
                if (a.Name > b.Name) { return 1; }
                return 0;
            })

            for (let z = 0; z < dataArr[y].length; z++) {
                const data = dataArr[y][z];



                const propertyValues = Object.values(data);
                let row = worksheet.addRow(propertyValues);

                row.eachCell((cell, number) => {
                    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                });


            }

            worksheet.columns.forEach(function (column) {
                var dataMax = 0;
                //@ts-ignore
                column.eachCell({ includeEmpty: true }, function (cell) {
                    dataMax = cell.value ? cell.value.toString().length : 0;
                })
                column.width = dataMax < 20 ? 20 : dataMax;
            });

        }

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, fileName + '.xlsx');
        });

    }


    public classDrawExport(classNames: any[], dataArr: any[], fileName: string): void {
        console.log('Data Array', dataArr);
        let workbook = new Workbook();

        // const logo = workbook.addImage({
        //     base64: base64Logo,
        //     extension: 'png',
        // });

        for (let y = 0; y < dataArr.length; y++) {

            let worksheet = workbook.addWorksheet(classNames[y]);

            //Logo row
            // worksheet.addImage(logo, {
            //     tl: { col: 0, row: 0 },
            //     ext: { width: 200, height: 63 }
            // });

            // worksheet.addRow([]);
            // worksheet.addRow([]);
            worksheet.addRow([]);
            worksheet.addRow([]);

            //Add new row
            let titleRow = worksheet.addRow([classNames[y]]);
            // Set font, size and style in title row.
            titleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'single', bold: true };

            //Blank Row
            worksheet.addRow([]);

            let header = [];
            if (dataArr[y][0]) {
                header = Object.keys(dataArr[y][0]);
            } else {
                header = Object.keys(dataArr[0][0]);
            }


            //@ts-ignore

            let headerRow = worksheet.addRow(header);

            headerRow.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ffffff' },
                    bgColor: { argb: 'ffffff' }
                }
                cell.font = { name: 'Calibri', family: 4, size: 12, bold: true };
                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
            });


            dataArr[y].sort(function (a: any, b: any) {
                if (a.Name < b.Name) { return -1; }
                if (a.Name > b.Name) { return 1; }
                return 0;
            })

            for (let z = 0; z < dataArr[y].length; z++) {
                const data = dataArr[y][z];

                const propertyValues = Object.values(data);
                let row = worksheet.addRow(propertyValues);

                row.eachCell((cell, number) => {
                    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                });


            }
            // if (header[6] === "Avg" && y !== 0) {
            //     worksheet.fillFormula(`G8:G${dataArr[y].length + 7}`, `('${classNames[y - 1]}'!F8 + '${classNames[y]}'!F8)/2`)
            // }


            worksheet.columns.forEach(function (column) {
                var dataMax = 0;
                //@ts-ignore
                column.eachCell({ includeEmpty: true }, function (cell) {
                    dataMax = cell.value ? cell.value.toString().length : 0;
                })
                column.width = dataMax < 20 ? 20 : dataMax;
            });

        }

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, fileName + '.xlsx');
        });

    }



}
import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/core/services/event.service';
import { EntryService } from 'src/app/core/services/entry.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  classArr: any = [];
  sidepotArr: any = [];
  sidepotEntries: any = [];
  eventId: any;
  searchField: any = "";

  reports: any = [
    // {
    //   tag: "Book Keeping",
    //   name: "All Entries",
    //   reportName: 'allEntries',
    //   description: "This will export all entries weather they are only submitted or marked paid."
    // },
    // {
    //   tag: "Book Keeping",
    //   name: "Purchase Report",
    //   reportName: 'purchaseReport',
    //   description: "This will generate a pdf report that containers an itemized receipt of every entry collected"
    // },
    {
      tag: "Billing",
      name: "Rodeo Paysheet",
      reportName: 'exportUnpaidBySidePot',
      description: "This will export a report that creates tabs for each class and displays the amount due per contestant."
    },
    {
      tag: "Showtime",
      name: "Rodeo Draw Sheet",
      reportName: 'sidepotsGroupedByClass',
      description: "This will export a report that creates tabs for each class then groups entries by sidepot."
    },
    // {
    //   tag: "Showtime",
    //   name: "Class Draws",
    //   description: "This will export a report that that has a tab for each class and randomizes each classes entries."
    // },
    {
      tag: "Event Management",
      name: "Additional Items",
      reportName: 'additionalItems',
      description: "This will export a report that displayes additional items purchased (Stalls, Electric Hookups...)"
    },
    {
      tag: "Showtime",
      name: "Exhibitions",
      reportName: 'expos',
      description: "This will export a report that displayes exhibitions by time grouped by timeslot"
    },
    {
      tag: "Showtime",
      name: "Barrel Race Run Sheet",
      reportName: 'runSheet',
      description: "This will export a report that displays all entries grouped by class, with columns visable for rollover and selected sidepots"
    },
    {
      tag: "Billing",
      name: "Barrel Race Paysheet",
      reportName: 'paysheetAll',
      description: "This will export a report that lists contestant information and the amount they owe."
    },
  ];

  constructor(private route: ActivatedRoute, private entry: EntryService, private event: EventService, private excelService: ExcelService) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');
    this.event.getClasses(this.eventId).subscribe(
      response => {
        this.classArr = response;
      },
      err => console.log(err)
    );


    this.entry.getAllSidePotEntrys(this.eventId).subscribe(
      response => {
        this.sidepotEntries = response;
      },
      err => console.log(err)
    );


  }

  openReport(name: string) {
    console.log(name);
    switch (name) {
      case 'allEntries':
        return this.allEntries();
      case 'purchaseReport':
        return this.purchaseReport();
      case 'sidepotsGroupedByClass':
        return this.sidepotsGroupedByClass();
      case 'exportUnpaidBySidePot':
        return this.exportUnpaidBySidePot();
      case 'additionalItems':
        return this.additionalItems();
      case 'expos':
        return this.exposReport();
      case 'paysheetAll':
        return this.paysheetByLastName();
      case 'runSheet':
        return this.runSheet();
    }
  }

  formatSheetName(value: string) {
    // This function returns the formatted string on user input
    const regex = /[*?:\\\/\[\]]/g;
    return value.replace(regex, '');
  }

  paysheetAll(): void {

    this.entry.getMoneyDue(this.eventId).subscribe(
      response => {
        let result: any = [];

        for (let y = 0; y < response.length; y++) {

          let entryObj = {
            "Name": response[y]['name'],
            "Email": response[y]['email'],
            "Phone": response[y]['phone'],
            "address": response[y]['address'],
            "Amount Due": response[y]['amountPaid'] / 100,
            "Paid": "N",
            "Notes": response[y]['notes']
          }

          result.push(entryObj);

        }

        this.excelService.exportAsExcelFile(["Cash entries"], [result], 'entries');


      },
      err => console.log(err)
    );



  }

  runSheet() {

    this.event.getClasses(this.eventId).subscribe(
      (classes: any) => {

        //Class Name
        var classNames: any = [];
        var result: any = {};
        var sidepotResults: any = {};

        for (let x = 0; x < classes.length; x++) {

          // const classHasDrawSelections = classes[x]['sidePots'].some((pot: any) => pot.drawSelection);

          result[classes[x]['_id']] = [];
          sidepotResults[classes[x]['_id']] = [];

          classes[x]['sidePots'].map((pot: any) => {

            if (!pot.drawSelection) {

              sidepotResults[classes[x]['_id']][pot['_id']] = {
                sidepotName: pot['name'],
                entries: []
              };
            }

          })

          classNames.push(classes[x]['name']);
        }

        this.entry.allEntriesForEvent(this.eventId).subscribe(entries => {

          // Lets massage a shit load of data

          // Rollovers
          // classId (From roll to value) 
          // class Entry _id: true 
          let rollovers: any = {};

          for (let y = 0; y < entries.length; y++) {
            const entry = entries[y];

            for (let classIndex = 0; classIndex < entry['classes'].length; classIndex++) {

              const classData = entry['classes'][classIndex];

              if (entry) {


                if (classData['rollovers'][0]) {
                  // This is the class id we are rolling to
                  if (!rollovers[classData['rollovers'][0]['rollTo']['_id']]) {
                    rollovers[classData['rollovers'][0]['rollTo']['_id']] = {};
                  }

                  // This is class entry id
                  rollovers[classData['rollovers'][0]['rollTo']['_id']][classData['entry']] = classData['class']['name'];
                }


                let rollover = classData['rollovers'][0] ? classData['rollovers'][0]['rollTo']['name'] : '';

                if (rollovers[classData['class']['_id']]) {
                  if (rollovers[classData['class']['_id']][entry['_id']]) {
                    rollover = rollovers[classData['class']['_id']][entry['_id']];
                  }

                }

                const sidepots = entry['sidePots'].filter((pot: any) => pot.class == classData['class']['_id'] && pot.name == classData['name']).map((pot: any) => {
                  if (!pot.sidePot.drawSelection) {
                    sidepotResults[classData['class']['_id']][pot['sidePot']['_id']]['entries'].push({
                      contestantName: entry['name'],
                      horseName: classData['name'],
                      time: "",
                    })
                    return pot.sidePot.name;
                  }
                });

                const potWithDrawSelection = entry['sidePots'].filter((pot: any) => pot.class == classData['class']['_id'] && pot.name == classData['name'] && pot['sidePot']['drawSelection'] == true);

                let hasDrawSelection = false;
                let drawSelectionOrder = 100; // To make entries without draw selection last

                if (potWithDrawSelection.length) {
                  hasDrawSelection = true;
                  drawSelectionOrder = potWithDrawSelection[0]['sidePot']['orderId'];
                }


                sidepots.sort(function (a: any, b: any) {
                  if (a < b) { return -1; }
                  if (a > b) { return 1; }
                  return 0;
                })

                let entryObj: any = {
                  contestantName: entry['name'],
                  horseName: classData['name'],
                  time: "",
                  rollover: rollover,
                  sidepots: sidepots.join(', '),
                  hasDrawSelection: hasDrawSelection,
                  drawSelectionOrder: drawSelectionOrder
                }

                //@ts-ignore
                result[classData['class']['_id']].push(entryObj);

                result[classData['class']['_id']].sort(function (a: any, b: any) {
                  if (a.drawSelectionOrder < b.drawSelectionOrder) { return -1; }
                  if (a.drawSelectionOrder > b.drawSelectionOrder) { return 1; }
                  return 0;
                });

              }

            }

          }



          console.log('Sidepot Results', Object.values(sidepotResults));

          this.excelService.runSheet(classNames, Object.values(result), Object.values(sidepotResults), 'run_sheet');


        })

      });

  }


  additionalItems() {
    this.event.getAdditionalItems(this.eventId).subscribe(
      additionalItems => {

        //Class Name
        var itemNames: any = [];
        var result: any = {};

        //@ts-ignore
        for (let x = 0; x < additionalItems.length; x++) {
          //@ts-ignore
          result[additionalItems[x]['_id']] = [];

          //@ts-ignore
          itemNames.push(additionalItems[x]['name']);
        }

        this.entry.getAllAdditionalItemPurchases(this.eventId).subscribe(
          response => {

            for (let y = 0; y < response.length; y++) {

              //@ts-ignore
              if (response[y]['entry']) {

                let entryObj: any = {
                  //@ts-ignore
                  "Name": response[y]['entry']['name'],
                  //@ts-ignore
                  "Quantity": response[y]['amount'],
                  //@ts-ignore
                  "Notes": response[y]['entry']['notes']
                }

                //@ts-ignore
                result[response[y]['item']['_id']].push(entryObj);

              }

            }

            this.excelService.itemExport(itemNames, Object.values(result), 'purchased_items');


          },
          err => console.log(err)
        );



      },
      err => console.log(err)
    );


  }

  exposReport() {
    this.event.getAdditionalItems(this.eventId).subscribe(
      additionalItems => {

        //Class Name
        var itemNames: any = [];
        var result: any = {};

        //@ts-ignore
        for (let x = 0; x < additionalItems.length; x++) {
          //@ts-ignore
          result[additionalItems[x]['_id']] = [];

          //@ts-ignore
          itemNames.push(this.formatSheetName(additionalItems[x]['name']));
        }

        this.entry.getAllAdditionalItemPurchases(this.eventId).subscribe(
          response => {

            for (let y = 0; y < response.length; y++) {

              //@ts-ignore
              if (response[y]['entry']) {

                let entryObj: any = {
                  //@ts-ignore
                  "Name": response[y]['entry']['name'],
                  //@ts-ignore
                  "Quantity": response[y]['amount'],
                  //@ts-ignore
                  "Notes": response[y]['entry']['notes']
                }

                //@ts-ignore
                for (let amountIndex = 0; amountIndex < response[y]['amount']; amountIndex++) {
                  //@ts-ignore
                  result[response[y]['item']['_id']].push(entryObj);
                }


              }

            }

            this.excelService.itemExport(itemNames, Object.values(result), 'exhibitions');


          },
          err => console.log(err)
        );



      },
      err => console.log(err)
    );


  }

  purchaseReport() {
    this.entry.purchaseSummary(this.eventId).subscribe(
      response => {
        var downloadURL = window.URL.createObjectURL(response);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "purchaseReport.pdf";
        link.click();
      });
  }

  exportUnpaidBySidePot(): void {

    this.event.getClasses(this.eventId).subscribe(
      classes => {

        //Class Name
        var classNames: any = [];
        var result: any = {};

        //@ts-ignore
        for (let x = 0; x < classes.length; x++) {
          //@ts-ignore
          result[classes[x]['_id']] = [];

          //@ts-ignore
          classNames.push(classes[x]['name']);
        }

        this.entry.getMoneyDueBySidePot(this.eventId).subscribe(
          response => {

            for (const [key, value] of Object.entries(response)) {

              //@ts-ignore
              for (let y = 0; y < value.length; y++) {

                // let entryObj = {
                //   "Name": value[y]['name'],
                //   "Email": value[y]['email'],
                //   "Phone": value[y]['phone'],
                //   "address": value[y]['address'],
                //   "Amount Due": value[y]['amountDue']['total'],
                //   "Paid": "N",
                //   "Notes": value[y]['notes']
                // }


                let entryObj = {
                  "Name": value[y]['name'],
                  "Phone": value[y]['phone'],
                  "Amount Due": value[y]['amountDue'],
                  "Notes": value[y]['notes'],
                }

                result[key].push(entryObj);

              }

            }

            this.excelService.unpaidSidePotExport(classNames, Object.values(result), 'cash_entries_by_sidepot');


          },
          err => console.log(err)
        );



      },
      err => console.log(err)
    );


  }

  reverseName(fullName: string) {
    const [firstName, lastName] = fullName.split(' ');
    return `${lastName}, ${firstName}`;
  }
  

  paysheetByLastName(): void {

    const results: any = [];

    this.entry.getMoneyDue(this.eventId).subscribe(
      response => {

        const sortedUsers = response.sort((a: any, b: any) => {
          const lastNameA = this.reverseName(a.name).split(", ").shift()!.toLowerCase(); // Extract and lowercase last name
          const lastNameB = this.reverseName(b.name).split(", ").shift()!.toLowerCase();

          if (lastNameA < lastNameB) {
            return -1;
          } else if (lastNameA > lastNameB) {
            return 1;
          } else {
            // If last names are equal, sort by first name (optional)
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          }
        });

        for (const [key, value] of Object.entries(sortedUsers)) {

          console.log(value)

          //@ts-ignore
          let entryObj = {
            "Name": this.reverseName(value['name']),
            "Phone": value['phone'],
            "Amount Due": parseInt(value['amountPaid']) / 100,
            "Notes": value['notes'],
          }

          results.push(entryObj);

        }

        this.excelService.paysheet("Paysheet", results, 'paysheet_by_last_name');


      },
      err => console.log(err)
    );


  }


  getSidePotName(id: any) {
    let name = "";

    for (let i = 0; i < this.classArr.length; i++) {

      for (let x = 0; x < this.classArr[i]['sidePots'].length; x++) {
        let sidepot = this.classArr[i]['sidePots'][x];
        if (sidepot['_id'] === id) {
          name = sidepot['name'];
        }

      }
    }

    return name;
  }

  sidepotsGroupedByClass() {
    let classNames: any = [];
    let classIds: any = [];
    let classData: any = {};

    this.entry.getAllSidePotEntrys(this.eventId).subscribe(
      response => {

        let entries = response;
        for (let i = 0; i < this.classArr.length; i++) {

          classNames.push(this.classArr[i].name);
          classIds.push(this.classArr[i]['_id']);

          for (let a = 0; a < classIds.length; a++) {
            classData[classIds[a]] = [];
          }

        }


        for (let y = 0; y < entries.length; y++) {
          let entry: any = {};
          entry = entries[y];
          let entryObj = {};

          try {

            entryObj = {
              "Name": this.getSidePotName(entry['sidePot']),
              "Rider": entry['entry']['name'],
              "Horse": entry['name'],
              "Time": "",
              "Place": "",
              "Points": ""
            }

          } catch (err) {
            console.error(y, entry);
          }

          classData[entry['class']].push(entryObj);

        }

        let res = [];

        //Loop Classes
        for (let i = 0; i < this.classArr.length; i++) {

          if (classData[this.classArr[i]['_id']]) {

            let data = {};

            //loop sidepots
            for (let s = 0; s < this.classArr[i]['sidePots'].length; s++) {
              let sidepotName = this.classArr[i]['sidePots'][s]['name'];
              //Loop sidepot entry that belongs in this sidepot group
              for (let x = 0; x < classData[this.classArr[i]['_id']].length; x++) {
                let item = classData[this.classArr[i]['_id']][x];
                if (sidepotName === item['Name']) {
                  //@ts-ignore

                  if (!data[sidepotName]) {
                    //@ts-ignore
                    data[sidepotName] = [];
                  }

                  //@ts-ignore
                  data[sidepotName].push(item);
                }

              }

              //@ts-ignore
              if (!data[sidepotName]) {
                //@ts-ignore
                data[sidepotName] = [];
              }

              //@ts-ignore
              data[sidepotName].push({
                "Horse": "",
                "Name": "",
                "Place": "",
                "Points": "",
                "Rider": "",
                "Time": ""
              }, {
                "Horse": "",
                "Name": "",
                "Place": "",
                "Points": "",
                "Rider": "",
                "Time": ""
              }, {
                "Horse": "",
                "Name": "",
                "Place": "",
                "Points": "",
                "Rider": "",
                "Time": ""
              });



            }

            res.push(data);

          }


        }

        this.excelService.sidePotExport(classNames, res, 'sidepots');

      },
      err => console.log(err)
    );


  }

  allEntries() {
    this.entry.allPopClasses(this.eventId).subscribe(
      response => {
        let entries = response;

        this.event.getClasses(this.eventId).subscribe(
          classArrRes => {

            let classArr: any = [];
            let classIdDate: any = [];
            let classData: any = {};
            classArr = classArrRes;

            let classNames: any = [];

            var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];


            for (let i = 0; i < classArr.length; i++) {
              for (let x = 0; x < classArr[i]['prices'].length; x++) {

                let currentDate = classArr[i]['prices'][x]['date'];
                let d = new Date(currentDate);
                let dayName = days[d.getDay() + 1];

                if (dayName === undefined) {
                  dayName = 'Sunday'
                }

                //@ts-ignore
                classNames.push(classArr[i].name + ' ' + dayName);
                classIdDate.push(classArr[i]['_id'] + "_" + classArr[i]['prices'][x]['date']);
              }

            }

            for (let a = 0; a < classIdDate.length; a++) {
              classData[classIdDate[a]] = [];
            }

            for (let y = 0; y < entries.length; y++) {
              let entry: any = {};
              entry = entries[y];

              for (let z = 0; z < entry['classes'].length; z++) {

                let entryClassObj: any = {};
                entryClassObj = entry['classes'][z];

                let entryObj = {
                  "Draw": "",
                  "Rider": entry['name'],
                  "Horse": entryClassObj['name'],
                  "Rollover": entryClassObj['rollover'] ? entryClassObj['rollover'] : '',
                  "District": entry['district'],
                  "Member #": entry['memberNumber'],
                  "Time": ""
                }


                classData[entryClassObj['class']['_id'] + "_" + entryClassObj['date']].push(entryObj);


              }


            }


            this.excelService.exportAsExcelFile(classNames, Object.values(classData), 'classes');

          },
          err => console.log(err)
        );




      },
      err => console.log(err)
    );

  }

}
